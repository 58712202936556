import { format, startOfDay } from "date-fns";

export  const DaySelector = ({ onDaySelect, setFilter }) => {
    const daysInMonth = (year, month) => new Array(new Date(year, month, 0).getDate()).fill(null).map((_, index) => index + 1);
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth()
    const days = daysInMonth(currentYear, currentMonth);

    const handleDayChange = (day) => {
        const formattedDate = startOfDay(new Date(currentYear, currentMonth, day));
        onDaySelect(format(formattedDate, 'yyyy-MM-dd')); // Converte para string YYYY-MM-DD
        setFilter('specificDay'); // Atualizar o filtro para 'specificDay'
      };

    return (
      <select className="border border-black rounded-md cursor-pointer font-medium px-2 py-4 " onChange={e => handleDayChange(`${e.target.value}`)}>
        {days.map(day => (
          <option  key={day} value={day}>DIA{day}</option>
        ))}
      </select>
    );
  }; 
