import React, { useState, useContext, useMemo } from 'react';
import styled from 'styled-components';
import { FeedbackContext } from '../context/FeedbackContext';
import { format, isEqual, isToday, isYesterday, startOfDay, subDays } from 'date-fns';
import { Calendar, List, User, Copy, Check } from 'lucide-react';
import { DaySelector } from '../components/DaySelect';

const DashboardContainer = styled.div`
  padding: 2rem;
  background-color: #ffffff;
  min-height: 100vh;
`;

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
  background-color: #000;
  padding: 1rem 2rem;
  border-radius: 0.5rem;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
`;

const DashboardTitle = styled.h1`
  font-size: 1.5rem;
  color: #ffffff;
  font-weight: 600;
`;

const StatsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1.5rem;
  margin-bottom: 2rem;
`;

const StatCard = styled.div`
  background-color: #ffffff;
  border-radius: 0.5rem;
  padding: 1.5rem;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  display: flex;
  align-items: center;
  transition: transform 0.2s ease-in-out;

  &:hover {
    transform: translateY(-5px);
  }
`;

const StatIcon = styled.div`
  margin-right: 1rem;
  padding: 0.75rem;
  border-radius: 50%;
  background-color: #000;
`;

const StatContent = styled.div``;

const StatTitle = styled.h3`
  font-size: 0.875rem;
  color: #6b7280;
  margin: 0;
  font-weight: 500;
`;

const StatValue = styled.p`
  font-size: 1.5rem;
  font-weight: 600;
  color: #111827;
  margin: 0;
`;

const FilterContainer = styled.div`
  display: flex;
  gap: 1rem;
  margin-bottom: 2rem;
  flex-wrap: wrap;
`;

const FilterButton = styled.button`
  padding: 0.5rem 1rem;
  background-color: ${props => props.active ? '#000' : '#ffffff'};
  color: ${props => props.active ? '#ffffff' : '#000'};
  border: 1px solid #000;
  border-radius: 0.375rem;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  font-weight: 500;

  &:hover {
    background-color: ${props => props.active ? '#059669' : '#d1fae5'};
  }
`;

const FeedbackList = styled.ul`
  list-style-type: none;
  padding: 0;
`;

const FeedbackItem = styled.li`
  background-color: #ffffff;
  
  border-radius: 0.5rem;
  padding: 1.5rem;
  margin-bottom: 1rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  border-left: 4px solid #000;
  transition: transform 0.2s ease-in-out;

  &:hover {
    transform: translateX(5px);
  }
`;

const FeedbackHeader = styled.div`
  display: flex;
  
  justify-content: space-between;
  margin-bottom: 0.5rem;
`;

const FeedbackName = styled.h3`
  font-size: 1rem;
  color: #111827;
  margin: 0;
  font-weight: 600;
`;

const FeedbackDate = styled.span`
  font-size: 0.875rem;
  color: #6b7280;
`;

const FeedbackContent = styled.p`
  font-size: 0.875rem;
  
  color: #374151;
  margin: 0;
  line-height: 1.5;
`;

const CopyButton = styled.button`
  padding: 0.25rem 0.5rem;
  background-color: #ffffff;
  color: #000000;
  border: 1px solid #000000;
  border-radius: 0.25rem;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  font-size: 0.75rem;
  display: flex;
  align-items: center;
  gap: 0.25rem;

  &:hover {
    background-color: #f0f0f0;
  }
`;

const CompleteButton = styled.button`
  padding: 0.5rem 1rem;
  background-color: ${props => props.completed ? '#4CAF50' : '#ffffff'};
  color: ${props => props.completed ? '#ffffff' : '#000000'};
  border: 1px solid ${props => props.completed ? '#4CAF50' : '#000'};
  border-radius: 0.25rem;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  font-size: 0.875rem;
  font-weight: 500;
  margin-top: 1rem;

  &:hover {
    background-color: ${props => props.completed ? '#45a049' : '#f0f0f0'};
  }
`;

const Dashboard = () => {
  const { pedidos, toggleConcluido } = useContext(FeedbackContext);
  const [filter, setFilter] = useState('specificDay');
  const [copiedStates, setCopiedStates] = useState({});
  const [specificDay, setSpecificDay] = useState('');


  const filteredFeedbacks = useMemo(() => {
    const today = new Date();
    switch (filter) {
      case 'today':
        return pedidos.filter(feedback => isToday(new Date(feedback.date)));
      case 'yesterday':
        return pedidos.filter(feedback => isYesterday(new Date(feedback.date)));
      case 'last7days':
        const sevenDaysAgo = subDays(today, 7);
        return pedidos.filter(feedback => new Date(feedback.date) >= sevenDaysAgo);
      case 'specificDay': // Nova condição para filtrar por um dia específico

      return pedidos.filter(feedback => {
        const feedbackDate = startOfDay(new Date(feedback.date));
        const targetDate = new Date(specificDay);
targetDate.setHours(24); // Definindo um horário que evita mudança de dia devido ao fuso horário
const adjustedTargetDate = startOfDay(targetDate);
        return isEqual(feedbackDate, adjustedTargetDate);
      })
      default:
        return pedidos;
    }
  }, [pedidos, filter, specificDay]);

  const handleToggleConcluido = async (id, currentStatus) => {
    try {
      await toggleConcluido(id, !currentStatus);
    } catch (error) {
      console.error("Error ao atualizar status do pedido:", error);
    }
  };

  const copyToClipboard = (text, id, field) => {
    navigator.clipboard.writeText(text).then(() => {
      setCopiedStates(prev => ({ ...prev, [id + field]: true }));
      setTimeout(() => setCopiedStates(prev => ({ ...prev, [id + field]: false })), 2000);
    });
  };

  return (
    <DashboardContainer>
      <Header>
        <DashboardTitle>Dashboard de pedidos</DashboardTitle>
      </Header>

      <StatsContainer>
        <StatCard>
          <StatIcon>
            <List size={24} color="#10b981" />
          </StatIcon>
          <StatContent>
            <StatTitle>Total de pedidos</StatTitle>
            <StatValue>{pedidos.length}</StatValue>
          </StatContent>
        </StatCard>
        <StatCard>
          <StatIcon>
            <Calendar size={24} color="#10b981" />
          </StatIcon>
          <StatContent>
            <StatTitle>Pedidos Hoje</StatTitle>
            <StatValue>{pedidos.filter(feedback => isToday(new Date(feedback.date))).length}</StatValue>
          </StatContent>
        </StatCard>
        <StatCard>
          <StatIcon>
            <User size={24} color="#10b981" />
          </StatIcon>
          <StatContent>
            <StatTitle>Clientes</StatTitle>
            <StatValue>{new Set(pedidos.map(f => f.email)).size}</StatValue>
          </StatContent>
        </StatCard>
      </StatsContainer>

      <FilterContainer>
        <FilterButton active={filter === 'all'} onClick={() => setFilter('all')}>Todos</FilterButton>
        <FilterButton active={filter === 'today'} onClick={() => setFilter('today')}>Hoje</FilterButton>
        <FilterButton active={filter === 'yesterday'} onClick={() => setFilter('yesterday')}>Ontem</FilterButton>
        <FilterButton active={filter === 'last7days'} onClick={() => setFilter('last7days')}>Últimos 7 dias</FilterButton>
        <DaySelector onDaySelect={setSpecificDay} setFilter={setFilter} />

      </FilterContainer>

      <FeedbackList>
        {filteredFeedbacks.map((feedback) => (
          <FeedbackItem key={feedback.id}>
            <FeedbackHeader>
              <FeedbackName>{feedback.email} - Calorias Pedidas {feedback.calories}</FeedbackName>
              <FeedbackDate>{format(new Date(feedback.date), 'dd/MM/yyyy HH:mm')}</FeedbackDate>
            </FeedbackHeader>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '0.5rem' }}>
              <FeedbackContent className='bold'>Mudanças na dieta:</FeedbackContent>
              <CopyButton onClick={() => copyToClipboard(feedback.dietChanges, feedback.id, 'changes')}>
                {copiedStates[feedback.id + 'changes'] ? <Check size={12} /> : <Copy size={12} />}
                {copiedStates[feedback.id + 'changes'] ? 'Copiado' : 'Copiar'}
              </CopyButton>
            </div>
            <FeedbackContent>{feedback.dietChanges}</FeedbackContent>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '1rem', marginBottom: '0.5rem' }}>
              <FeedbackContent className='bold'>Dieta:</FeedbackContent>
              <CopyButton onClick={() => copyToClipboard(feedback.diet, feedback.id, 'diet')}>
                {copiedStates[feedback.id + 'diet'] ? <Check size={12} /> : <Copy size={12} />}
                {copiedStates[feedback.id + 'diet'] ? 'Copiado' : 'Copiar'}
              </CopyButton>
            </div>
            <FeedbackContent>{feedback.diet}</FeedbackContent>
            <CompleteButton 
              completed={feedback.concluido} 
              onClick={() => handleToggleConcluido(feedback.id, feedback.concluido)}
            >
              {feedback.concluido ? 'Concluído' : 'Concluir'}
            </CompleteButton>
          </FeedbackItem>
        ))}
      </FeedbackList>
    </DashboardContainer>
  );
};

export default Dashboard;